import React from "react";

function UnderDevelopment() {
  return (
    <div>
      <div className="hero min-h-screen bg-base-200">
        <div className="text-center hero-content">
          <div className="max-w-md">
            <h1 className="mb-5 text-5xl font-bold">
              {" "}
              <i>escu</i>Design will soon be revealed!
            </h1>
            <p className="mb-5">
              This page is under development. Please come back later.
            </p>
            <button className="btn btn-primary">
              <a href="mailto:popescu.developer@gmail.com">Contact Us</a>
            </button>
          </div>
        </div>
      </div>
      {/* <div className="hero min-h-screen bg-base-200">
        <h1 className="mb-5 text-5xl font-bold"> escuDesign </h1>
      </div> */}
    </div>
  );
}

export default UnderDevelopment;
