import logo from './logo.svg';
import React, { Component } from "react";
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import UnderDevelopment from './Pages/UnderDevelopment';

class App extends Component {
  render() {
    return (
      <>
        <Router>
          <div data-theme="forest" className="App">
            {/* <Navbar /> */}
            <Routes>
              <Route exact path="/" element={<UnderDevelopment />}></Route>
              {/* <Route exact path="/photography" element={<HomePhoto />}></Route>
              <Route exact path="/web" element={<HomeWeb />}></Route>
              <Route exact path="/photography/auto" element={<PhotoAuto />}></Route> */}
            </Routes>
          </div>
        </Router>
      </>
    );
  }
}

export default App;
